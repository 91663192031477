/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Progress,
  Text,
  FormControl,
  FormLabel,
  Select,
  Input,
  Flex,
  useToast,
} from '@chakra-ui/react'
import {
  useLoadActivityListV1,
  useSetActivityRef,
  useUpdateActivityRef,
  useLoadGanntChildActivity,
} from '../../../../hooks/activity.hooks'
import {
  getMessageUniqueId,
  getCurrentDateUTC,
} from '../../../../utils/common.util'
import { useSharedContext } from '../../../../context/shared.context'
import { sharedConst } from '../../../../utils/action.constant'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../../repository/repo.utils'
const AddRefModel = ({
  isOpen,
  onClose,
  activity,
  isUpdateModel,
  referenceListRes,
  setrefreshRefList,
  selectedActivity,
}) => {
  const [options, setOptions] = useState([])
  const [isError, setisError] = useState(false)
  const [orderFlag, setOrderFlag] = useState('')
  const [prereqTye, setprereqTye] = useState(1)
  const [offSetType, setoffSetType] = useState('')
  const [offSet, setOffSet] = useState('')
  const { mutate, isLoading } = useLoadActivityListV1()
  const { mutate: setActivityRef } = useSetActivityRef()
  const { mutate: updateActivityRef } = useUpdateActivityRef()
  const { mutate: loadChildActivities } = useLoadGanntChildActivity()
  const { state, dispatch } = useSharedContext()
  const toast = useToast({
    duration: 5000,
  })
  useEffect(() => {
    if (!isOpen) return
    loadChildActivities(
      {
        parent_activity_id: activity.activity_id,
        page_start: 0,
        page_limit: 30,
        startFrom: 0,
        sort_flag: 0,
        flag: 6,
        datetime_end: getCurrentDateUTC(),
        datetime_start: '1970-01-01 00:00:00',
      },
      {
        onSuccess: res => {
          // console.log(res.data.response)
          let finalData = toLoadGanttData(res.data.response)
          let data = finalData?.map(
            ({
              activity_cuid_1,
              activity_cuid_2,
              activity_cuid_3,
              activity_id,
              activity_title,
              operating_asset_first_name,
              activity_type_category_id,
              activity_type_id,
              parent_activity_id,
            }) => ({
              details: {
                activity_cuid_1,
                activity_cuid_2,
                activity_cuid_3,
                operating_asset_first_name,
                activity_id,
                activity_type_category_id,
                activity_type_id,
                parent_activity_id,
              },
              label: activity_title,
              value: `${
                activity_id +
                '|' +
                activity_title +
                '|' +
                activity_type_category_id
              }`,
            })
          )
          setOptions([...data])

          if (!!isUpdateModel) {
            setOrderFlag(isUpdateModel?.activity_title)
            setprereqTye(isUpdateModel?.activity_mapping_flag_is_prerequisite)
            let offsetData = JSON.parse(isUpdateModel.activity_inline_data)
            setoffSetType(offsetData.offset_type)
            setOffSet(offsetData.offset_time)
          }
        },
        onError: async error => {
          setisError(true)
        },
      }
    )
  }, [isOpen])
  const toLoadGanttData = data => {
    let finalData = []
    data.map(el => {
      finalData?.push(el)
      el?.child_workflows.map(val => {
        finalData.push(val)
      })
    })
    return finalData
  }
  const setRefAcitivity = () => {
    if (!!orderFlag) {
      options.map(option => {
        if (option.label === orderFlag) {
          if (!!isUpdateModel) {
            updateActivityRef(
              {
                old_refrence_activity_id: isUpdateModel.activity_id,
                refrence_activity_id: option.details.activity_id,
                activity_type_id: selectedActivity.activity_type_id,
                activity_id: selectedActivity.activity_id,
                message_unique_id: getMessageUniqueId(),
                activity_flag_is_prerequisite: prereqTye,
                ghantt_config: JSON.stringify({
                  offset_time: offSet,
                  offset_type: offSetType,
                }), //lead or lag
                parent_activity_id: option.details.parent_activity_id,
              },
              {
                onSuccess: () => {
                  setOrderFlag('')
                  setrefreshRefList(pre => !pre)
                  dispatch({
                    type: sharedConst.REFRESH_GANNT_VIEW,
                  })
                },
              }
            )
          } else {
            setActivityRef(
              {
                refrence_activity_id: option.details.activity_id,
                activity_type_id: selectedActivity.activity_type_id,
                activity_id: selectedActivity.activity_id,
                message_unique_id: getMessageUniqueId(),
                activity_flag_is_prerequisite: prereqTye,
                ghantt_config: JSON.stringify({
                  offset_time: offSet,
                  offset_type: offSetType,
                }),
                parent_activity_id: option.details.parent_activity_id,
              },
              {
                onSuccess: async res => {
                  toast({
                    title: res?.data?.response?.[0]?.message,
                    status: 'success',
                  })
                  setOrderFlag('')
                  setrefreshRefList(pre => !pre)
                  dispatch({
                    type: sharedConst.REFRESH_GANNT_VIEW,
                  })
                },
              }
            )
          }
        }
      })
    }
    onClose()
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Dependency</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isLoading && (
              <>
                <FormControl id="selectType">
                  <FormLabel>Select Task / Sub Project</FormLabel>
                  <Select
                    size="sm"
                    placeholder="Select..."
                    value={orderFlag}
                    onChange={e => {
                      setOrderFlag(e.target.value)
                    }}
                  >
                    {options
                      // .filter(
                      //   item =>
                      //     // item.details.activity_type_category_id ==
                      //     // activity.activity_type_category_id &&
                      //     // item.label !== activity.activity_title ||
                      //     !referenceListRes
                      //       .map(val => val.activity_id)
                      //       .includes(item.details.activity_id)
                      // )
                      .map((option, i) => (
                        <option key={i} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Select Dependency Type</FormLabel>
                  <Select
                    placeholder="Select option"
                    size="sm"
                    value={prereqTye}
                    onChange={e => setprereqTye(e.target.value)}
                  >
                    <option value={1}>FS (Finish to Start)</option>
                    <option value={2}>SF (Start to Finish)</option>
                    <option value={3}>FF (Finish to Finish)</option>
                    <option value={4}>SS (Start to Start)</option>
                  </Select>
                </FormControl>
                <Flex>
                  <FormControl>
                    <FormLabel>Select Lag/Lead</FormLabel>
                    <Select
                      placeholder="Select option"
                      size="sm"
                      value={offSetType}
                      onChange={e => setoffSetType(e.target.value)}
                    >
                      <option value="lag">Lag (+)</option>
                      <option value="lead">Lead (-)</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Select No. of days</FormLabel>
                    <Input
                      placeholder="No. of days"
                      value={offSet}
                      onChange={e => setOffSet(e.target.value)}
                      type="number"
                      size="sm"
                    />
                  </FormControl>
                </Flex>
              </>
            )}
            {isLoading && (
              <Progress colorScheme={localStorage.getItem('color')} my={5} size="md" isIndeterminate />
            )}
            {!orderFlag && (
              <Text color="red">Please select the Reference activity!</Text>
            )}
            {isError && (
              <Text color="red">Something went wrong please try again!</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              borderRadius="sm"
              size="sm"
              variant="outline"
              colorScheme={localStorage.getItem('color')}
              mr={3}
              onClick={e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Add Ref Modal',
                  label: 'Close',
                })
                onClose(e)
              }}
            >
              Close
            </Button>
            {!isError && (
              <Button
                borderRadius="sm"
                size="sm"
                variant="solid"
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                isLoading={isLoading}
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Add Ref Modal',
                    label: 'Set',
                  })
                  setRefAcitivity()
                }}
              >
                Set
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddRefModel
