/* eslint-disable react-hooks/exhaustive-deps */
// TODO: make use of activity intead of this
import { useDisclosure } from '@chakra-ui/hooks'
import {
	Avatar,
	AvatarGroup,
	Box,
	Divider,
	HStack,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Slider,
	SliderFilledTrack,
	SliderTrack,
	Tag,
	Text,
	VStack,
	useColorMode,
} from '@chakra-ui/react'
import { Collapse } from '@chakra-ui/transition'
import { format } from 'date-fns'
import React, { useCallback, useState, useEffect } from 'react'
import { FiCopy } from 'react-icons/fi'
import {
	MdArrowDropDown,
	MdArrowDropUp,
	MdDateRange,
	MdMoreVert,
	MdTimelapse,
} from 'react-icons/md'
import { useMount } from 'react-use'
import Card from '../../../../components/card'
import {
	useLoadActivityDetails,
	useLoadActivityParticipant,
	useDeleteActivityRef,
} from '../../../../hooks/activity.hooks'
import { toSnakeCase } from '../../../../utils/common.util'
import ReferenceTimeline from './referenceTimeline'
import { SmartFormContextProvider } from '../../../../context/smart-form.context'
import FormEditPanel from '../../../form-edit-panel'
import { getMessageUniqueId } from '../../../../utils/common.util'
import { DependencyType } from '../../../ganttChart/gantt.utils'
import { useSharedContext } from '../../../../context/shared.context'
import { useLanguageContext } from '../../../../context/locale.context'
import { sharedConst } from '../../../../utils/action.constant'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../../../repository/repo.utils'
import { GA_CATEGORY_WORKSPACE_PANEL } from './../../../repository/repo.utils'
import { useAuthContext } from '../../../../context/auth.context'
const ReferenceFile = ({
	activity,
	index,
	listType,
	selectedActivity,
	setrefreshRefList,
	refModel,
}) => {
	const { toggleColorMode } = useColorMode()
	const { isOpen, onToggle } = useDisclosure()
	const { isOpen: isTimelineOpen, onClose, onOpen } = useDisclosure()
	const {
		isOpen: isFormEditView,
		onOpen: onOpenFormEditView,
		onClose: onCloseFormEdit,
	} = useDisclosure()
	const [selected, setselected] = useState(null)
	const [openFormView, setopenFormView] = useState(null)
	const [participants, setparticipants] = useState([])
	const { mutate, data } = useLoadActivityDetails()
	const { mutate: participantMutate } = useLoadActivityParticipant()
	const { mutate: deleteActivityReference } = useDeleteActivityRef()
	const { state, dispatch } = useSharedContext()
	const { state: authState } = useAuthContext()
	const {
		activity_lead_operating_asset_first_name,
		operating_asset_first_name,
		activity_type_category_name,
		activity_type_name,
		activity_status_name,
		activity_cuid_1,
		activity_cuid_2,
		activity_cuid_3,
		activity_datetime_created,
		activity_datetime_start_expected,
		activity_datetime_end_deferred,
		participant_list = [],
		activity_workflow_completion_percentage: completion,
	} = data || {}

	const {
		state: { locale },
	} = useLanguageContext()

	const loadParticipants = useCallback(params => {
		participantMutate(
			{ ...params },
			{
				onSuccess: async data => {
					setparticipants(data ? data.list : [])
				},
			}
		)
	})

	useMount(() => {
		mutate({
			activity_id: activity.activity_id,
			activity_type_id: activity.activity_type_id,
		})
		loadParticipants({ activity_id: activity.activity_id })
	})

	const onDeleteRef = () => {
		deleteActivityReference(
			{
				refrence_activity_id: activity.activity_id,
				activity_id: selectedActivity.activity_id,
				activity_type_id: selectedActivity.activity_type_id,
				message_unique_id: getMessageUniqueId(),
			},
			{
				onSuccess: async data => {
					dispatch({
						type: sharedConst.REFRESH_GANNT_VIEW,
					})
					setrefreshRefList(pre => !pre)
				},
			}
		)
	}

	return (
		<>
			{isTimelineOpen && (
				<ReferenceTimeline
					className={`reference-time-line`}
					isOpen={isTimelineOpen}
					onClose={onClose}
					activity={selected}
					onFormSelection={f => {
						const { activity_id, activity_type_id } = activity
						// TODO: only super adming, lead , owner can perform form submission.
						// FIXME: @jaya please chekc .
						setopenFormView({
							...f,
							disableEdit: true,
							activity_id,
							activity_type_id,
						})
						onOpenFormEditView()
						onClose()
					}}
				/>
			)}
			{isFormEditView && (
				<SmartFormContextProvider>
					<FormEditPanel
						isOpen={isFormEditView}
						onClose={onCloseFormEdit}
						activity={activity}
						formData={openFormView}
						refillForm={() => {}}
						isFormEnable={false}
					/>
				</SmartFormContextProvider>
			)}
			<Card
				minH='40px'
				maxH='250px'
				key={index}
				className={`reference-files-card-container`}
			>
				<HStack alignItems='center'>
					<VStack alignItems='flex-start' flex={1}>
						<Text
							className={`reference-files-text-${toSnakeCase(
								activity?.activity_title
							)}`}
							flex={1}
							fontSize='md'
							color='black'
							textTransform='capitalize'
						>
							{activity?.activity_title}
						</Text>
						<Text
							fontSize='12px'
							mb={1}
							className={`reference-files-text-${toSnakeCase(
								activity?.activity_type_category_name
							)}`}
						>
							{activity?.activity_type_category_name} :
							<Text
								as='span'
								fontWeight='500'
								className={`reference-files-text-${toSnakeCase(
									activity?.activity_type_name
								)}`}
							>
								{activity?.activity_type_name}
							</Text>
							<Text
								fontSize='12px'
								mb={1}
								className={`reference-files-text-${toSnakeCase(
									activity?.activity_type_category_name
								)}`}
							>
								{activity && activity.activity_mapping_flag_is_prerequisite > 0
									? DependencyType[
											activity.activity_mapping_flag_is_prerequisite
									  ]
									: ''}
								{activity?.activity_inline_data &&
								!!JSON.parse(activity?.activity_inline_data)?.offset_type
									? (JSON.parse(activity?.activity_inline_data)?.offset_type ===
									  'lag'
											? '+'
											: '-') +
									  JSON.parse(activity.activity_inline_data)?.offset_time
									: null}
							</Text>
						</Text>
					</VStack>
					{!!data && listType !== 'prerequisites' && (
						<Icon
							className={`reference-files-icon-${toSnakeCase(
								activity?.activity_title
							)} ${onToggle ? 'expanded' : ''}`}
							cursor='pointer'
							as={!isOpen ? MdArrowDropDown : MdArrowDropUp}
							w={7}
							h={7}
							onClick={() => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_WORKSPACE_PANEL,
								//   action: 'Reference Files',
								//   label: 'Expand',
								// })
								onToggle()
							}}
						/>
					)}
					{!!data && (
						<Menu
							className={`reference-files-menu-${toSnakeCase(
								activity?.activity_title
							)} ${onToggle ? 'expanded' : ''}`}
						>
							<MenuButton
								as={IconButton}
								_focus={{
									bg: 'secondary',
								}}
								_hover={{
									bg: 'secondary',
								}}
								border='none'
								borderRadius='md'
								boxShadow='md'
								aria-label='Sort'
								size='sm'
								icon={
									<Icon
										className={`reference-files-arrow-icon-${toSnakeCase(
											activity?.activity_title
										)}`}
										as={MdMoreVert}
										w={6}
										h={6}
									/>
								}
								variant='outline'
							/>
							<MenuList
								className={`reference-files-menu-list-${toSnakeCase(
									activity?.activity_title
								)}`}
							>
								<MenuItem
									className={`reference-files-menu-item-${toSnakeCase(
										activity?.activity_title
									)}`}
									key='timeline'
									size='sm'
									py={1}
									onClick={() => {
										// sectionDetailedTrack({
										//   category: GA_CATEGORY_WORKSPACE_PANEL,
										//   action: 'Reference Files',
										//   label: 'View Timeline',
										// })
										// dataLayerTagManager('button_click', {
										//   viewName: 'Reference Files',
										//   buttonName: 'View Timeline',
										// })
										setselected(data)
										onOpen()
									}}
								>
									<Text
										className={`reference-files-text-${toSnakeCase(
											'View Timeline'
										)}`}
										fontSize='14px'
									>
										{locale['View Timeline']}
									</Text>
								</MenuItem>

								{listType === 'prerequisites' && (
									<>
										<MenuItem
											className={`reference-files-menu-item-${toSnakeCase(
												activity?.activity_title
											)}`}
											key='timeline'
											size='sm'
											py={1}
											onClick={() => {
												// sectionDetailedTrack({
												//   category: GA_CATEGORY_WORKSPACE_PANEL,
												//   action: 'Reference Files',
												//   label: 'Update Dependency',
												// })
												// dataLayerTagManager('button_click', {
												//   viewName: 'Reference Files',
												//   buttonName: 'Update Dependency',
												// })
												refModel(activity)
											}}
										>
											<Text
												className={`reference-files-text-${toSnakeCase(
													'View Timeline'
												)}`}
												fontSize='14px'
											>
												{locale['Update Dependency']}
											</Text>
										</MenuItem>
										<MenuItem
											className={`reference-files-menu-item-${toSnakeCase(
												activity?.activity_title
											)}`}
											key='timeline'
											size='sm'
											py={1}
											onClick={() => {
												// sectionDetailedTrack({
												//   category: GA_CATEGORY_WORKSPACE_PANEL,
												//   action: 'Reference Files',
												//   label: 'Delete Dependency',
												// })
												// dataLayerTagManager('button_click', {
												//   viewName: 'Reference Files',
												//   buttonName: 'Delete Dependency',
												// })
												onDeleteRef()
											}}
										>
											<Text
												className={`reference-files-text-${toSnakeCase(
													'View Timeline'
												)}`}
												fontSize='14px'
											>
												{locale['Delete Dependency']}
											</Text>
										</MenuItem>
									</>
								)}
							</MenuList>
						</Menu>
					)}
				</HStack>
				<Collapse
					in={isOpen}
					animateOpacity
					className={`reference-files-text-${toSnakeCase('View Timeline')} ${
						isOpen ? 'expanded' : ''
					}`}
				>
					<Divider py={1} />
					<Box px={1} mt={2} shadow='md'>
						<HStack spacing={1} alignItems='flex-start'>
							<VStack flex={1} alignItems='flex-start'>
								<HStack alignItems='flex-start' justifyContent='flex-start'>
									{activity_status_name && (
										<Tag
											className={`reference-files-text-tag-${toSnakeCase(
												activity_status_name
											)}`}
											variant='outline'
											borderRadius='full'
											colorScheme={localStorage.getItem('color')}
											size='md'
											fontSize='12px'
											mb={1}
										>
											{activity_status_name}
										</Tag>
									)}
									{!!completion && (
										<HStack
											spacing={2}
											flex={1}
											className={`reference-files-container-${toSnakeCase(
												activity_status_name
											)}`}
										>
											<Slider
												className={`reference-files-slider`}
												aria-label='slider-ex-2'
												colorScheme={localStorage.getItem('color')}
												defaultValue={completion || 0}
												w='150px'
											>
												<SliderTrack className={`reference-files-slider-track`}>
													<SliderFilledTrack
														className={`reference-files-slider-filled-track`}
													/>
												</SliderTrack>
											</Slider>
											<Text
												color={localStorage.getItem('color')}
												className={`reference-files-text-completion`}
											>
												{`${completion}%` || 0}
											</Text>
										</HStack>
									)}
								</HStack>
								<Text
									fontSize='12px'
									mb={1}
									className={`reference-files-text-${toSnakeCase(
										activity_type_category_name || ''
									)}`}
								>
									{activity_type_category_name} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`reference-files-text-${toSnakeCase(
											activity_type_name || ''
										)}`}
									>
										{activity_type_name}
									</Text>
								</Text>
							</VStack>
							<VStack
								w='200px'
								justifyContent='flex-start'
								alignItems='flex-start'
							>
								<Text
									fontSize='12px'
									className={`reference-files-text-creator`}
								>
									{locale['Creator']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`reference-files-text-${toSnakeCase(
											operating_asset_first_name || ''
										)}`}
									>
										{operating_asset_first_name}
									</Text>
								</Text>
								{activity_lead_operating_asset_first_name && (
									<Text fontSize='12px' className={`reference-files-lead`}>
										{locale['Lead']} :{' '}
										<Text
											as='span'
											fontWeight='500'
											className={`reference-files-text-${toSnakeCase(
												activity_lead_operating_asset_first_name
											)}`}
										>
											{activity_lead_operating_asset_first_name}
										</Text>
									</Text>
								)}
							</VStack>
							<VStack justifyContent='flex-start' alignItems='flex-start'>
								<Text fontSize='12px'>
									<Icon as={MdDateRange} w={4} h={4} />
									{!!activity_datetime_created
										? format(
												new Date(
													activity_datetime_created.split(' ').join('T')
												),
												'dd MMM yyyy'
										  )
										: !!activity_datetime_start_expected
										? format(
												new Date(
													activity_datetime_start_expected.split(' ').join('T')
												),
												'dd MMM yyy'
										  )
										: null}
								</Text>
								{!!activity_datetime_end_deferred && (
									<Text fontSize='12px' className={`reference-files-text`}>
										<Icon
											as={MdTimelapse}
											w={4}
											h={4}
											className={`reference-files-icon-time-lapse`}
										/>
										{activity_datetime_end_deferred.includes('date') ||
										activity_datetime_end_deferred.includes('0000-00-00')
											? activity_datetime_end_deferred
											: format(
													new Date(activity_datetime_end_deferred),
													'dd MMM yyyy'
											  )}
									</Text>
								)}
							</VStack>
						</HStack>
						<HStack
							justifyContent='space-between'
							mt={2}
							alignItems='center'
							spacing={4}
						>
							{activity_cuid_1 && (
								<Text
									mb={1}
									fontSize='12px'
									className={`reference-files-text-cuid1`}
								>
									{locale['CUID 1']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`reference-files-text-cuid1-${toSnakeCase(
											activity_cuid_1
										)}`}
									>
										{activity_cuid_1}
									</Text>{' '}
									<Icon
										as={FiCopy}
										className={`reference-files-copy-icon-${toSnakeCase(
											activity_cuid_1
										)}`}
										cursor='pointer'
										onClick={() => {
											// sectionDetailedTrack({
											//   category: GA_CATEGORY_WORKSPACE_PANEL,
											//   action: 'Reference Files',
											//   label: 'CUID 1 copied',
											// })
											// dataLayerTagManager('button_click', {
											//   viewName: 'Reference Files',
											//   buttonName: 'CUID 1 copied',
											// })
											navigator.clipboard.writeText(activity_cuid_1)
										}}
										w={3}
										h={3}
									/>{' '}
								</Text>
							)}
							{activity_cuid_2 && (
								<Text
									mb={1}
									fontSize='12px'
									className={`reference-files-text-cuid2`}
								>
									{locale['CUID 2']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`reference-files-text-cuid2-${toSnakeCase(
											activity_cuid_2
										)}`}
									>
										{activity_cuid_2}
									</Text>{' '}
									<Icon
										className={`reference-files-copy-icon-${toSnakeCase(
											activity_cuid_2
										)}`}
										as={FiCopy}
										cursor='pointer'
										onClick={() => {
											// sectionDetailedTrack({
											//   category: GA_CATEGORY_WORKSPACE_PANEL,
											//   action: 'Reference Files',
											//   label: 'CUID 2 copied',
											// })
											// dataLayerTagManager('button_click', {
											//   viewName: 'Reference Files',
											//   buttonName: 'CUID 2 copied',
											// })
											navigator.clipboard.writeText(activity_cuid_2)
										}}
										w={3}
										h={3}
									/>{' '}
								</Text>
							)}
							{activity_cuid_3 && (
								<Text
									mb={1}
									fontSize='12px'
									className={`reference-files-text-cuid3`}
								>
									{locale['CUID 3']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`reference-files-text-cuid3-${toSnakeCase(
											activity_cuid_3
										)}`}
									>
										{activity_cuid_3}
									</Text>{' '}
									<Icon
										className={`reference-files-copy-icon-${toSnakeCase(
											activity_cuid_3
										)}`}
										as={FiCopy}
										cursor='pointer'
										onClick={() => {
											// sectionDetailedTrack({
											//   category: GA_CATEGORY_WORKSPACE_PANEL,
											//   action: 'Reference Files',
											//   label: 'CUID 3 copied',
											// })
											// dataLayerTagManager('button_click', {
											//   viewName: 'Reference Files',
											//   buttonName: 'CUID 3 copied',
											// })
											navigator.clipboard.writeText(activity_cuid_3)
										}}
										w={3}
										h={3}
									/>{' '}
								</Text>
							)}
							{participants && !!participants.length && (
								<AvatarGroup
									className={`reference-files-avatar-group`}
									justifySelf='flex-end'
									size='sm'
									max={3}
									spacing='-5px'
									cursor='pointer'
								>
									{(participants || []).map(p => {
										return (
											<Avatar
												className={`reference-files-avatar-${toSnakeCase(
													p.operating_asset_first_name
												)}`}
												key={p.asset_id}
												name={p.operating_asset_first_name}
											/>
										)
									})}
									<Avatar
										name='+'
										key='new'
										bg='secondary'
										color={localStorage.getItem('color')}
										className={`reference-files-avatar-plus-icon`}
									/>
								</AvatarGroup>
							)}
						</HStack>
					</Box>
				</Collapse>
			</Card>
		</>
	)
}

export default ReferenceFile
