import React from 'react'
import {
  Drawer,
  Box,
  Icon,
  Tag,
  Text,
  VStack,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
} from '@chakra-ui/react'
import Timeline from '../../../../../components/timeline'
import { MdDateRange, MdTimelapse } from 'react-icons/md'
import { format } from 'date-fns'
import { useLanguageContext } from '../../../../../context/locale.context'

const ReferenceTimeline = ({ activity, isOpen, onClose, onFormSelection }) => {
  const {
    activity_datetime_end_deferred,
    activity_datetime_start_expected,
    activity_datetime_created,
    operating_asset_first_name,
  } = activity || {}
  const {
    state: { locale },
  } = useLanguageContext()
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader boxShadow="sm">
          <Box>
            <VStack alignItems="flex-start" flex={1}>
              <Text
                flex={1}
                fontSize="md"
                color="black"
                textTransform="capitalize"
              >
                {activity?.activity_title}
              </Text>

              <HStack>
                {activity?.activity_status_name && (
                  <Tag
                    variant="outline"
                    borderRadius="full"
                    colorScheme={localStorage.getItem('color')}
                    size="md"
                    fontSize="12px"
                    mb={1}
                  >
                    {activity?.activity_status_name}
                  </Tag>
                )}
                <Text fontSize="12px" mb={1}>
                  {activity?.activity_type_category_name} :
                  <Text as="span" fontWeight="500">
                    {activity?.activity_type_name}
                  </Text>
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Text fontSize="12px">
                  Creator :{' '}
                  <Text as="span" fontWeight="500">
                    {operating_asset_first_name}
                  </Text>
                </Text>

                <Text fontSize="12px">
                  <Icon as={MdDateRange} w={4} h={4} />
                  {activity_datetime_created
                    ? format(new Date(activity_datetime_created), 'dd MMM yyyy')
                    : format(
                        new Date(activity_datetime_start_expected),
                        'dd MMM yyy'
                      )}
                </Text>
                {!!activity_datetime_end_deferred && (
                  <Text fontSize="12px">
                    <Icon as={MdTimelapse} w={4} h={4} />
                    {activity_datetime_end_deferred.includes('date') ||
                    activity_datetime_end_deferred.includes('0000-00-00')
                      ? activity_datetime_end_deferred
                      : format(
                          new Date(activity_datetime_end_deferred),
                          'dd MMM yyyy'
                        )}
                  </Text>
                )}
              </HStack>
            </VStack>
          </Box>
        </DrawerHeader>
        <DrawerBody>
          <Timeline
            activity={activity}
            filter={[
              {
                value: 'ALL',
                label: locale['All'],
              },
            ]}
            onFormSelection={onFormSelection}
          />
        </DrawerBody>
        <DrawerFooter bg="secondary" minH="70px"></DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default ReferenceTimeline
