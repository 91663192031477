/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import {
	Center,
	VStack,
	Skeleton,
	Heading,
	Flex,
	Button,
	useDisclosure,
	IconButton,
} from '@chakra-ui/react'
import { useLoadReferenceList } from '../../../../hooks/activity.hooks'
import ReferenceFile from './files'
import { REFERENCE_TYPE } from '../util'
import { MdAddCircle } from 'react-icons/md'
import AddRefModel from '../reference-list/AddRefModel'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../../../repository/repo.utils'
import { GA_CATEGORY_WORKSPACE_PANEL } from './../../../repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'

const ReferenceList = ({
	selectedActivity,
	type,
	filter,
	listType,
	parantActivity = {},
}) => {
	const { mutate, isLoading, data } = useLoadReferenceList()
	const [referenceListRes, setreferenceListRes] = useState({})
	const [refreshRefList, setrefreshRefList] = useState(false)
	const [isUpdateModel, setisUpdateModel] = useState(null)

	const {
		activity_id: workflow_activity_id,
		activity_type_category_id,
		activity_type_id,
	} = selectedActivity

	const {
		state: { locale },
	} = useLanguageContext()

	const { isOpen, onOpen, onClose } = useDisclosure()
	const loadReferenceFiles = useCallback(({ start = 0, tag_type_id = 0 }) => {
		mutate(
			{
				workflow_activity_id,
				activity_type_category_id: !tag_type_id ? activity_type_category_id : 0,
				activity_type_id: !tag_type_id ? activity_type_id : 0,
				tag_type_id,
				tag_id: 0,
				page_start: start,
				page_limit: 50,
				flag:
					listType === 'prerequisites'
						? 3
						: type === REFERENCE_TYPE.INTERNAL
						? 1
						: 2,
			},
			{
				onSuccess: async data => {
					setreferenceListRes(data)
				},
			}
		)
	})

	useEffect(() => {
		if (!!filter && !listType) {
			loadReferenceFiles({ start: 0, tag_type_id: filter.value })
		}
	}, [filter])

	useEffect(() => {
		if (!!selectedActivity) {
			loadReferenceFiles({ start: 0, tag_type_id: 0 })
		}
	}, [selectedActivity, refreshRefList])

	const onRefModel = val => {
		setisUpdateModel(val)
		onOpen()
	}
	if (!!isLoading) {
		return (
			<VStack
				h='calc(100% - 160px)'
				overflowY='scroll'
				w='full'
				bg='secondary'
				p={2}
			>
				{[1, 2, 3, 4].map(i => {
					return (
						<Skeleton
							boxShadow='md'
							borderRadius='md'
							key={i}
							p={2}
							w='full'
							h='70px'
						/>
					)
				})}
			</VStack>
		)
	}

	if (
		!referenceListRes ||
		(referenceListRes &&
			!!referenceListRes.list &&
			!referenceListRes.list.length)
	) {
		return (
			<>
				{listType === 'prerequisites' && (
					<IconButton
						colorScheme={localStorage.getItem('color')}
						aria-label='Search database'
						float='right'
						onClick={() => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_WORKSPACE_PANEL,
							//   action: 'Reference List',
							//   label: 'Open Add Ref Modal',
							// })
							onOpen()
							setisUpdateModel(null)
						}}
						icon={<MdAddCircle size='30px' />}
					/>
				)}
				<AddRefModel
					isOpen={isOpen}
					onOpen={onOpen}
					onClose={onClose}
					activity={parantActivity}
					setrefreshRefList={setrefreshRefList}
					isUpdateModel={isUpdateModel}
					referenceListRes={referenceListRes?.list?.map(val =>
						getReferenceDetails(val)
					)}
					selectedActivity={selectedActivity}
				/>
				<VStack
					h='calc(100% - 160px)'
					overflowY='scroll'
					w='full'
					bg='secondary'
					p={2}
				>
					<Center>
						<Heading
							color={localStorage.getItem('color')}
							size='md'
							fontWeight='400'
						>
							{locale['No Reference Found!']}
						</Heading>
					</Center>
				</VStack>
			</>
		)
	}

	const getReferenceDetails = data => {
		console.log('data', data)
		if (type === REFERENCE_TYPE.INTERNAL) {
			const {
				parent_activity_id,
				parent_activity_title,
				parent_activity_type_id,
				parent_activity_type_name,
				parent_activity_type_category_name,
				activity_mapping_flag_is_prerequisite,
				activity_type_category_id,
				activity_inline_data,
			} = data
			return {
				activity_id: parent_activity_id,
				activity_title: parent_activity_title,
				activity_type_id: parent_activity_type_id,
				activity_type_name: parent_activity_type_name,
				activity_type_category_name: parent_activity_type_category_name,
				activity_mapping_flag_is_prerequisite:
					activity_mapping_flag_is_prerequisite,
				activity_type_category_id: activity_type_category_id,
				activity_inline_data,
			}
		} else if (type === REFERENCE_TYPE.EXTERNAL) {
			const {
				activity_id,
				activity_title,
				activity_type_id,
				activity_type_name,
				activity_type_category_name,
				activity_inline_data,
			} = data

			return {
				activity_id,
				activity_title,
				activity_type_id,
				activity_type_name,
				activity_type_category_name,
				activity_inline_data,
			}
		}
	}

	return (
		<>
			{listType === 'prerequisites' && (
				<IconButton
					colorScheme={localStorage.getItem('color')}
					aria-label='Search database'
					float='right'
					onClick={() => {
						// sectionDetailedTrack({
						//   category: GA_CATEGORY_WORKSPACE_PANEL,
						//   action: 'Reference List',
						//   label: 'Open Add Ref Modal',
						// })
						onOpen()
						setisUpdateModel(null)
					}}
					icon={<MdAddCircle size='30px' />}
				/>
			)}
			<AddRefModel
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				activity={parantActivity}
				setrefreshRefList={setrefreshRefList}
				isUpdateModel={isUpdateModel}
				referenceListRes={referenceListRes?.list?.map(val =>
					getReferenceDetails(val)
				)}
				selectedActivity={selectedActivity}
			/>
			<VStack
				h='calc(100% - 160px)'
				overflowY='scroll'
				w='full'
				bg='secondary'
				p={2}
			>
				{((referenceListRes && referenceListRes.list) || []).map(
					(contact, i) => {
						if (!!contact.parent_activity_type_id) {
							return (
								<ReferenceFile
									activity={getReferenceDetails(contact)}
									listType={listType}
									index={i}
									selectedActivity={selectedActivity}
									setrefreshRefList={setrefreshRefList}
									refModel={onRefModel}
								/>
							)
						} else {
							return <></>
						}
					}
				)}

				{referenceListRes && !!referenceListRes.isMore ? (
					<Flex>
						<Button
							borderRadius='full'
							size='xs'
							px={2}
							variant='outline'
							color='brand.800'
							isLoading={isLoading}
							loadingText='Loading'
							onClick={() => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_WORKSPACE_PANEL,
								//   action: 'Reference List',
								//   label: 'Load More',
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Reference Files',
								//   buttonName: 'Load More',
								// })
								loadReferenceFiles({
									start: referenceListRes.page_start,
								})
							}}
						>
							{locale['Load more']}
						</Button>
					</Flex>
				) : null}
			</VStack>
		</>
	)
}

export default ReferenceList
