/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
	Flex,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Spacer,
	IconButton,
	useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdSort } from 'react-icons/md'
import CustomMenu from '../../../components/menu'
import { useLoadReferenceFilters } from '../../../hooks/activity.hooks'
import ReferenceList from './reference-list'
import { REFERENCE_TYPE } from './util'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
// import { dataLayerTagManager } from '../../repository/repo.utils'

const ReferenceFileContainer = ({ activity, isOverviewOpen }) => {
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: { authData },
	} = useAuthContext()
	const [selectedFilter, setselectedFilter] = useState({
		label: locale['All'],
		Value: 'All',
	})
	const [filterList, setFilterList] = useState([
		{ label: locale['All'], Value: 'All' },
	])

	const { mutate } = useLoadReferenceFilters()
	useEffect(() => {
		mutate(
			{
				workflow_activity_id: activity.activity_id,
			},
			{
				onSuccess: async data => {
					const filters = (data || [])
						?.map(i => {
							if (!!i.tag_type_id) {
								return {
									value: i.tag_type_id,
									label: i.tag_type_name,
								}
							}
						})
						.filter(Boolean)
					setFilterList([
						{
							label: locale['All'],
							value: 'All',
						},
						...filters,
					])
				},
			}
		)
	}, [activity])

	return (
		<Flex className={`reference-files-container`}>
			<Tabs
				className={`reference-files-tabs-container`}
				variant='enclosed'
				w='100%'
				bg='white'
				colorScheme={localStorage.getItem('color')}
				maxH={isOverviewOpen ? 'calc(100vh - 300px)' : 'calc(100vh - 180px)'}
				minH={isOverviewOpen ? 'calc(100vh - 300px)' : 'calc(100vh - 180px)'}
			>
				<TabList>
					<Tab className={`reference-files-tab-refered-in`}>
						{locale['Refered In']}
					</Tab>
					<Tab className={`reference-files-tab-refered-out`}>
						{locale['Refered Out']}
					</Tab>
					{authData?.asset_type_flag_enable_gantt_chart === 1 &&
					activity?.activity_type_category_id === 11 ? (
						<Tab className={`reference-files-tab-prerequisites`}>
							{locale['Prerequisites']}
						</Tab>
					) : null}
					<Spacer />

					<CustomMenu
						className={`reference-files-tab-custom-menu`}
						iconProps={{
							position: 'absolute',
							right: '20px',
							size: 'sm',
						}}
						optionList={filterList}
						selected={selectedFilter}
						onSelect={item => {
							// dataLayerTagManager('button_click', {
							//   viewName: 'Reference Files',
							//   buttonName: 'Filter',
							// })
							setselectedFilter(item)
						}}
						icon={MdSort}
					/>
				</TabList>
				<TabPanels>
					<TabPanel p={3} className={`reference-files-tab-refered-in-list`}>
						<ReferenceList
							className={`reference-files-tab-refered-in-container`}
							selectedActivity={activity}
							type={REFERENCE_TYPE.INTERNAL}
							filter={selectedFilter}
							listType={null}
						/>
					</TabPanel>
					<TabPanel p={3} className={`reference-files-tab-refered-out-list`}>
						<ReferenceList
							selectedActivity={activity}
							type={REFERENCE_TYPE.EXTERNAL}
							filter={selectedFilter}
							listType={null}
						/>
					</TabPanel>
					<TabPanel p={3} className={`reference-files-tab-prerequisites-list`}>
						<ReferenceList
							className={`reference-files-tab-prerequisites-container`}
							selectedActivity={activity}
							type={REFERENCE_TYPE.INTERNAL}
							filter={selectedFilter}
							listType={'prerequisites'}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>
	)
}

export default ReferenceFileContainer
